import React from 'react'

import JumpSvg from '../Jump'

const Twitter = props => (
	<JumpSvg {...props}>
		<path d="M8,16 C3.581722,16 0,12.418278 0,8 C0,3.581722 3.581722,0 8,0 C12.418278,0 16,3.581722 16,8 C16,12.418278 12.418278,16 8,16 Z M6.536,12.22 C10.084,12.22 12.024,9.28 12.024,6.732 C12.024,6.648 12.024,6.564 12.02,6.484 C12.396,6.212 12.724,5.872 12.984,5.484 C12.64,5.636 12.268,5.74 11.876,5.788 C12.276,5.548 12.58,5.172 12.724,4.72 C12.352,4.94 11.94,5.1 11.5,5.188 C11.148,4.812 10.648,4.58 10.092,4.58 C9.028,4.58 8.164,5.444 8.164,6.508 C8.164,6.66 8.18,6.808 8.216,6.948 C6.612,6.868 5.192,6.1 4.24,4.932 C4.076,5.216 3.98,5.548 3.98,5.9 C3.98,6.568 4.32,7.16 4.84,7.504 C4.524,7.496 4.228,7.408 3.968,7.264 C3.968,7.272 3.968,7.28 3.968,7.288 C3.968,8.224 4.632,9 5.516,9.18 C5.356,9.224 5.184,9.248 5.008,9.248 C4.884,9.248 4.764,9.236 4.644,9.212 C4.888,9.98 5.6,10.536 6.444,10.552 C5.784,11.068 4.952,11.376 4.048,11.376 C3.892,11.376 3.74,11.368 3.588,11.348 C4.432,11.9 5.448,12.22 6.536,12.22 Z" />
	</JumpSvg>
)

export default Twitter

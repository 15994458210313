import React from 'react'

import JumpSvg from '../Jump'

const Moon = props => (
	<JumpSvg {...props}>
		<path d="M16,8.70222222 C15.6109785,12.9118297 12.013796,16.0921201 7.78824575,15.9622829 C3.56269547,15.8324458 0.167554209,12.4373045 0.037717076,8.21175425 C-0.0921200574,3.98620398 3.08817031,0.389021531 7.29777778,0 C5.46698838,2.47684597 5.72378363,5.92040284 7.9016904,8.0983096 C10.0795972,10.2762164 13.523154,10.5330116 16,8.70222222 Z" />
	</JumpSvg>
)

export default Moon

import React from 'react'

import JumpSvg from '../Jump'

const Speech = props => (
	<JumpSvg {...props} viewBox="0 -1 16 17">
		<ellipse cx="8" cy="6.5" rx="8" ry="6.5" />
		<polygon points="1 15 3 10 5 12" />
	</JumpSvg>
)

export default Speech

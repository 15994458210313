import React from 'react'

import JumpSvg from '../Jump'

const LinkedIn = props => (
	<JumpSvg {...props}>
		<path d="M12.4898667,11.7698667 L12.4898667,8.64666667 C12.4898667,6.97333333 11.5965333,6.19466667 10.4056,6.19466667 C9.44453333,6.19466667 9.01386667,6.72346667 8.77386667,7.0944 L8.77386667,6.32266667 L6.9632,6.32266667 C6.9872,6.83386667 6.9632,11.7698667 6.9632,11.7698667 L8.77386667,11.7698667 L8.77386667,8.72773333 C8.77386667,8.56533333 8.7856,8.40266667 8.8336,8.2864 C8.96426667,7.96106667 9.26213333,7.62426667 9.76213333,7.62426667 C10.4176,7.62426667 10.6794667,8.12373333 10.6794667,8.85573333 L10.6794667,11.7701333 L12.4898667,11.7701333 L12.4898667,11.7698667 Z M5.05573333,5.5792 C5.68693333,5.5792 6.08026667,5.16053333 6.08026667,4.6376 C6.06853333,4.10346667 5.68693333,3.6968 5.06773333,3.6968 C4.44853333,3.6968 4.04346667,4.1032 4.04346667,4.6376 C4.04346667,5.16053333 4.43653333,5.5792 5.04426667,5.5792 L5.05573333,5.5792 Z M8,16 C3.58186667,16 0,12.4181333 0,8 C0,3.5816 3.58186667,0 8,0 C12.4181333,0 16,3.5816 16,8 C16,12.4181333 12.4181333,16 8,16 Z M5.96106667,11.7698667 L5.96106667,6.32266667 L4.15066667,6.32266667 L4.15066667,11.7698667 L5.96106667,11.7698667 Z" />
	</JumpSvg>
)

export default LinkedIn
